import logo from './logo.svg';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import { useForm } from "react-hook-form";
import Button from '@mui/material/Button';
import { Box, FormControlLabel, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import React from 'react';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { CheckBox } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function App() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = React.useState("");
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => {
    fetch(`https://node-coc-mail-generator.vercel.app/?players=${encodeURIComponent(data.players)}&message=${encodeURIComponent(data.message)}&separator=${encodeURIComponent(data.separator)}&sort=${encodeURIComponent(data.sort)}&capitalize=${encodeURIComponent(data.capitalize)}`)
      .then(response => response.json())
      .then(json => {
        setData(json.data)
        handleOpen()
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <div>


      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '45ch' },
            padding: 2
          }}>
           <h3>Detail</h3>

          <div className=''>
            <TextField id='players' {...register("players", { required: true })} multiline maxRows={4} label="Players to" variant="outlined" />
          </div>


          <div className=''>
            <TextField id='message' {...register("message", { required: true })} label="Message" variant="outlined" />
          </div>
          <div>
            <h3>Advanced Options</h3>

            <div className=''>
              <TextField id='separator' defaultValue=', ' {...register("separator", { required: true })} label="Players separator" variant="outlined" />
            </div>
            <Box marginLeft={1}>
              <div>
                <input type="checkbox" {...register("sort")}></input>
                <span>Sort</span>
              </div>
              <div>
                <input type="checkbox" {...register("capitalize")}></input>
                <span>Capitalize</span>
              </div>
            </Box>
          </div>
          <div className='Container'>
            <Button
              type="submit"
              size='medium'
              variant="contained">Submit
            </Button>
          </div>
        </Box>
      </form>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Generated Mail
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {data}
            </Typography>
            <Button
              onClick={() => { navigator.clipboard.writeText(data) }}
              size='small'
              variant="contained">Copy to clipboard
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>

  );
}